.header {
  @apply bg-white shadow-sm;
}

.headerBody {
  @apply mx-auto py-4 px-4 sm:px-6 lg:px-8;
}

.headerTitle {
  @apply text-lg leading-6 font-semibold text-gray-900;
}

.container {
  @apply flex flex-row grow overflow-hidden;
}

.body {
  @apply flex-1 overflow-y-auto px-4 sm:px-6 lg:px-8;
}

.bodySection {
  @apply min-w-0 flex-1 h-full flex flex-col lg:order-last;

  > h1 {
    @apply text-3xl text-zur-primary mb-12 mt-0;
  }
}

.sidebar {
  @apply hidden w-96 bg-white border-gray-200 border-l overflow-y-auto lg:block px-4 sm:px-6 lg:px-8 py-10;
}

.title {
  @apply text-2xl leading-6 text-zur-primary mb-4;
}

.pageHeading {
  @apply text-center font-bold text-4xl mt-16;
}

.sidebarTitle {
  @apply text-lg leading-6 font-medium text-zur-primary mb-4;
}
